// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WebSocketComponent from './components/WebSocketComponent';  // WebSocketComponent를 import
import Login from './components/Login';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/AICC" element={<WebSocketComponent />} />
        {/* 다른 라우트들 */}
      </Routes>
    </Router>
  );
}

/*
const App = () => {
  return (
    <div className="App">
      
      <Login />
      
    </div>
  );
};
*/

/*  
  <WebSocketComponent />
*/

export default App;