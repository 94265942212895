// components/TitleBar.jsx
import React from 'react';
import logo from './azailogo.png';  // 로고 이미지 파일을 import

const TitleBar = ({ title }) => {
  return (
    <div className="title-bar">
      
      <div className="title-bar-subtitle">
        {/* import된 이미지 사용 및 CSS 클래스 적용 */}
        <img src={logo} alt="Logo" className="title-logo" />
        <h2>POPBIO AICC</h2>
        <h1 style={{ marginLeft: 'auto' }}>{title}</h1>
      </div>

    </div>
  );
};

export default TitleBar;