// components/UserList.jsx
import React, { useEffect, useState } from 'react';
import { FaEdit, FaSave } from 'react-icons/fa'; // React Icons에서 편집 아이콘 import

const SERVER = 'https://popbio.ai'

const UserList = ({
  phoneNumberList,
  phoneNumberList2,
  selectedPhoneNumber, 
  setSelectedPhoneNumber,                            
  selectedMessageList, 
  selectedMessageList_json,
  selectedMessageList_json2,
  connData,
  userNo,
  isCallActive
}) => {

  const [editingIndex, setEditingIndex] = useState(null);
  const [editName, setEditName] = useState('');

  useEffect(() => {
    //console.log('connData :::', connData);
  }, [connData]);


  // 상담고객 번호 클릭 이벤트
  const handlePhoneNumberClick = (phoneNumber, index) => {
    /*
    connData.map((data) => {
      console.log(`connData [${index}] ::: ${data.caller}`);
    }); 
    */

    setSelectedPhoneNumber(phoneNumber);
    
    const seqNos = connData[index].seq_nos;
    console.log(`[UserList] handlePhoneNumberClick index: ${index} , phoneNumber: ${phoneNumber}`);
  
    //selectedMessageList(userNo, seqNos);
    //selectedMessageList_json(userNo, seqNos);
    selectedMessageList_json2(userNo, phoneNumber);
  };


  // 클라이언트 이름 변경
  const handleEditName = (index, currentName) => {
    setEditingIndex(index);
    setEditName(currentName || null);
  };


  // 클라이언트 이름 저장
  const handleSaveName = async(index, number) => {
    // 기존 이름
    const currentName = phoneNumberList2[index].clientName;
    const newName = editName;
    console.log(`[UserList] handleSaveName ::: 이름 변경 : ${currentName} -> ${newName}`);
    
    // 변경할 이름이 기존 이름과 동일 할 경우 종료
    if (currentName === newName) {
      console.log(`[UserList] handleSaveName ::: 동일 이름 변경 없음`);
      setEditingIndex(null);
      return;
    }

    // UI 업데이트
    phoneNumberList2[index].clientName = editName;
    setEditingIndex(null);

    // 클라이언트 이름 저장
    fetchSaveClientName(userNo, number, editName);
  };


  // 화면 출력
  return (
    <div className="user-list">
      <h5 className="user-list-header-name">상담 고객</h5>

      <ul style={{ pointerEvents: isCallActive ? 'none' : 'auto', opacity: isCallActive ? 0.5 : 1 }}>
        {phoneNumberList2.map((list, index) => (

        <li key={index}>
        {editingIndex === index ? (
          <>
            <input
              value={editName}
              maxLength={20}
              onChange={(e) => setEditName(e.target.value == '' ? null : e.target.value)}
              style={{ width: '150px', marginRight: '5px' }} // 너비 조절 및 오른쪽 여백 추가
            />
            <FaSave 
              onClick={() => handleSaveName(index, list.number)}
              style={{ marginLeft: '10px', cursor: 'pointer' }}
            />
          </>
        ) : (
          <>
            <li
              key={index}
              onClick={() => handlePhoneNumberClick(list.number, index)}
              className={selectedPhoneNumber === list.number ? 'selected' : ''}
              >
                {list.clientName != null ? `${list.clientName} (${list.number})` : list.number}
              
              <FaEdit 
              onClick={() => handleEditName(index, list.clientName)}
              style={{ marginLeft: '10px', cursor: 'pointer' }}
              />
            </li>
            
            
          </>
        )}
        </li>
          
        ))}
      </ul>

    </div>
  );


  /**
   * <span onClick={() => handlePhoneNumberClick(list.number, index)}>
              {list.name ? `${list.name} (${list.number})` : list.number}
            </span>
<span
              key={index}
              onClick={() => handlePhoneNumberClick(list.number, index)}
              className={selectedPhoneNumber === list.number ? 'selected' : ''}
            >
              {list.name ? `${list.name} (${list.number})` : list.number}
            </span>

   * <button onClick={() => handleSaveName(index, list.number)}>저장</button>
   * <button onClick={() => setEditingIndex(null)}>취소</button>



  return (
    <div className="user-list">
      <h5 className="user-list-header-name">상담 고객</h5>

      <ul style={{ pointerEvents: isCallActive ? 'none' : 'auto', opacity: isCallActive ? 0.5 : 1 }}>
        {phoneNumberList2.map((list, index) => (

          <li
            key={index}
            onClick={() => handlePhoneNumberClick(list.number, index)}
            className={selectedPhoneNumber === list.number ? 'selected' : ''}
          >
            {list.name != null ? `${list.name} (${list.number})` : list.number}
          </li>
          
        ))}
      </ul>

    </div>
  );
  */
};


// 클라이언트 이름 저장
async function fetchSaveClientName(userNo, number, clientName) {
  //console.log(`[UserList] fetchSaveClientName ::: 회원번호 : ${userNo} , 번호 : ${number} , 변경할 이름 : ${clientName}`);
  
  try {
    const URL = `${SERVER}/api/aicc/saveClientName`

    const data = {
      userNo      : userNo,
      phoneNumber : number,
      clientName  : clientName
    }

    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    //if (!response.ok) throw new Error('Failed to save client name');

    //const result = await response.json();
    //console.log(`[UserList] fetchSaveClientName ::: 결과 : ${result}`);

    if (response.ok) {
      console.log('고객 이름이 성공적으로 업데이트되었습니다');
    } else {
      console.error('고객 이름 업데이트 실패');
    }

  } catch (error) {
    console.error(`[UserList] fetchSaveClientName ::: 오류 발생 : ${error}`);
  }
};

export default UserList;