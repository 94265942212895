// 이 소스가 기본 소스.

import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';

const ChatBox = ({ filteredMessages_json, summary }) => {
  const messagesEndRef = useRef(null);

  // 새로운 메시지가 추가될 때마다 스크롤을 가장 아래로 이동
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [filteredMessages_json, summary]);

  // summary 변경 시 처리
  // useEffect(() => {}, [summary]);


  
  // JSON 파싱
  let parsedMessages;
  let messageType = null;
  let hasMessages = false;

  try {
    messageType = typeof filteredMessages_json;
    
    switch (messageType) {
      case 'string':
        parsedMessages = JSON.parse(filteredMessages_json);
        break;
      case 'object':
        parsedMessages = Array.isArray(filteredMessages_json) ? filteredMessages_json : [filteredMessages_json];
        break;
      default:
        parsedMessages = filteredMessages_json;
    }

    hasMessages = parsedMessages && Array.isArray(parsedMessages) && parsedMessages.length > 0;
  } catch (error) {
    console.error('[ChatBoxJSON] JSON 파싱 오류:', error.message);
    parsedMessages = [];
  }
  
  const hasSummary = summary && summary.length > 0;
  
  // 통화 요약 줄바꿈 처리
  const formatText = (text) => {
    return text.split('\n').map((line, index, array) => 
      index === array.length - 1 ? line : <>
        {line}
        <br />
      </>
    );
  };

  // 날짜 변환
  const formatDate = (dateString) => {
    if (!dateString) return '';

    const formatText = 'yyyy년 MM월 dd일 HH시 mm분';
    const date = format(new Date(dateString), formatText);
    return date;
  };

  // UI 출력
  return (
    <div className="chat-box-container">
      <div className="chat-box">
        <ul className="chat-messages">
          {hasMessages ? (
            parsedMessages.flatMap((session) => [
              <li key={`session-start-${session.id}`} className="session-start-time">
                {formatDate(session.startDt)}
              </li>,
              session.content.map((message, messageIndex) => (
                message.text && (
                  <li
                    key={`${session.seqNo}-${messageIndex}`}
                    className={`chat-message ${
                      message.type === 1 ? 'right' : message.type === 2 ? 'summary' : 'left'
                    }`}
                  >
                    {message.type === 2 ? formatText(message.text) : message.text }
                  </li>
                )
              ))
            ])
          ) : (
            <li>메시지가 없습니다.</li>
          )}
          <div ref={messagesEndRef} />
        </ul>
      </div>
      <div className="chat-footer">
        <ul>{hasSummary ? formatText( summary ) : '상담 요약이 없습니다.'}</ul>
      </div>
    </div>
  );
  
  /*
  return (
    <div className="chat-box-container">
      <div className="chat-box">
        <ul className="chat-messages">
          {allMessages.length > 0 ? (
            allMessages.flatMap((session, sessionIndex) =>
              session.content ? (
                // parsedMessages의 형식
                session.content.map((message, messageIndex) => (
                  message.text && (
                    <li
                      key={`${session.seqNo}-${messageIndex}`}
                      className={`chat-message ${message.type === 1 ? 'right' : 'left'}`}
                    >
                      {message.text}
                    </li>
                  )
                ))
              ) : (
                // callHistory의 형식
                session.text && (
                  <li
                    key={`callHistory-${sessionIndex}`}
                    className={`chat-message ${session.type === 1 ? 'right' : 'left'}`}
                  >
                    {session.text}
                  </li>
                )
              )
            )
          ) : (
            <li>메시지가 없습니다.</li>
          )}
          <div ref={messagesEndRef} />
        </ul>
      </div>
      <div className="chat-footer">
        <ul>상담 요약이 들어갈 예정입니다.</ul>
      </div>
    </div>
  );
  */
};

export default ChatBox;