//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * History
 * 2024-10-16
 *  - setPhoneNumberList2 : 전화번호 목록 상태 관리2 (전화번호, 이름) 추가
 * 2024-10-21
 *  - kcb_dev 브랜치로 이전
 *  - 웹소켓 연결 주소 변경 (계발 환경으로)
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// components/WebSocketComponent.jsx
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';

import UserList from './UserList';
import ChatBox from './ChatBox';
import ChatBoxJSON from './ChatBoxJSON';
import TitleBar from './TitleBar';  // TitleBar 컴포넌트 임포트
import '/react/test/src/ChatPage.css';  // 스타일을 위한 CSS 파일
import { set } from 'date-fns';


//const SERVER = 'http://192.168.0.33:8000'
const SERVER = 'https://popbio.ai'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const WebSocketComponent = () => {

  const location = useLocation();
  const [user, setUser] = useState(null);

  const [socket, setSocket] = useState(null);  // 소켓 상태 관리
  
  const [receivedData, setReceivedData] = useState([]);  // 수신받은 데이터를 저장할 상태
  const [receivedData_json, setReceivedData_json] = useState([]);  // 수신받은 데이터를 저장할 상태

  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);  // 선택된 전화번호 상태 관리
  const [phoneNumberList, setPhoneNumberList] = useState([]);  // 전화번호 목록 상태 관리
  const [phoneNumberList2, setPhoneNumberList2] = useState([]);  // 전화번호 목록 상태 관리2 (전화번호, 이름)
  
  const [title, setTitle] = useState("");  // 타이틀 상태 관리

  const [connData, setConnData] = useState([]);
  const [userNo, setUserNo] = useState(null);
  //const [callId, setCallId] = useState(null);

  const [transcription, setTranscription] = useState(null); // 음성 인식 데이터 (STT)
  
  // 새로운 데이터 아이템 생성
  const [newDataItem, setNewDataItem] = useState([{seqNo:0, recodingNo:0, content: []}]);

  const [summary, setSummary] = useState(null);
  const [isCallActive, setIsCallActive] = useState(false); // 통화 활성화 여부

  const [macAddress, setMacAddress] = useState(null); // 상담원의 IP전화 MAC 주소
  
  // 로그인 결과 조회
  useEffect(() => {
    // 로그인 결과 조회
    if (location.state) {
      //console.log('로그인 결과:::', location.state);
      setUser(location.state.user);
      setUserNo(location.state.user.userNo);
    }
  }, [location]);


  // 크롬 브라우저 [영어 -> 한국어] 번역 방지
  useEffect(() => {
    // 크롬 브라우저 감지
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    
    if (isChrome) {
      //console.log('크롬 브라우저 감지');
      
      // 메타 태그 생성
      const noTranslateMeta = document.createElement('meta');
      noTranslateMeta.name = 'google';
      noTranslateMeta.content = 'notranslate';
      
      // head에 메타 태그 추가
      document.head.appendChild(noTranslateMeta);
      
      // html 태그에 클래스 추가
      document.documentElement.classList.add('notranslate');
    }
  }, []);


  // 초기 설정
  useEffect(() => {
    // 로그인 결과 조회
    let userNo = null;
    if (location.state) {
      setUser(location.state.user);
      userNo = location.state.user.userNo;
      //console.log('로그인 userNo :::', userNo);
    }

    //let macAddress = null; // 상담원의 IP전화 MAC 주소
    
    const initializeComponent = async () => {
      // 상담원의 IP전화 MAC 주소 조회
      let macAddress = null;
      try {
        const response = await fetch('http://localhost:9876/mac');

        if (!response.ok) {
          throw new Error('HTTP error! status ::: ', response.status);
        }

        const data  = await response.json();
        macAddress = data.mac;
        setMacAddress(macAddress);
      } catch (error) {
        console.log('상담원의 IP전화 MAC 주소 조회 에러:', error);
      }

      console.log('[WebSocketComponent] 상담원의 IP전화 MAC 주소 :::', macAddress);

      // 웹소켓 연결
      const socket = io(SERVER, {
        query: { userNo: userNo, mac: macAddress },
        reconnectionDelay: 1000, // 재연결 대기 시간
        reconnectionDelayMax: 5000, // 최대 재연결 대기 시간
        reconnectionAttempts: 10 // 재연결 시도 횟수
      });

      // 웹소켓 연결 에러
      socket.on('connect_error', (error) => {
        console.error('웹소켓 연결 에러:', error);
      });
  

      // 생성된 소켓을 상태로 설정
      setSocket(socket);
  

      // 웹소켓 연결 완료 시 실행
      socket.on('connection', async (id, connData) => {
        //console.log('[WebSocketComponent] 웹소켓 연결 완료 ::: (id : ', id, ')');
        setTitle(location.state.user.name);
  
        setConnData(connData);
        //console.log( '[WebSocketComponent] connData :::', connData );
        
        // connData에서 전화번호 추출
        // TODO item 에 담긴 seq_nos 도 전화번호와 같이 관리가 되어야 한다.
        const newPhoneNumbers = connData.map(item => item.caller);

        // phoneNumberList 업데이트
        /*
        setPhoneNumberList(prevList => {
          const updatedList = [...new Set([...newPhoneNumbers, ...prevList])];
          return updatedList;
        });
        */

        // connData에서 전화번호와 이름 추출
        const newPhoneNumbers2 = connData.map(item => ({
          number: item.caller,
          clientName: item.clientName
        }));

        // phoneNumberList2 업데이트
        setPhoneNumberList2(prevList => {
          //const updatedList = [...new Set([...newPhoneNumbers2, ...prevList])];
          //const updatedList = [...new Set([...newPhoneNumbers2])];
          const updatedList = Array.from(new Set(newPhoneNumbers2.map(JSON.stringify))).map(JSON.parse);
          //console.log('[WebSocketComponent] setPhoneNumberList2_prevList :::\n', prevList);
          //console.log('[WebSocketComponent] setPhoneNumberList2_updatedList :::\n', updatedList);
          return updatedList;
        });


        // 첫번째 번호에 매칭되는 통화 데이터 전부 조회
        /*
        try {
          await fetchMessageList(userNo, newSeqNos[0]);
        } catch (error) {
          console.log('에러: ', error);
          setReceivedData([]); // 빈 화면 표시
        }
        */


        // 첫번째 번호에 매칭되는 통화 데이터 전부 조회 2 (JSON 방식)
        try {
          //await fetchMessageList_json(userNo, newSeqNos[0]); // 1번 방식
          await fetchMessageListToJSON(userNo, newPhoneNumbers[0]); // 2번 방식 
          //console.log('[WebSocketComponent] socket connection ::: (조회 할 번호 : ', newPhoneNumbers[0], ')');
        } catch (error) {
          console.log('에러: ', error);
          setReceivedData([]); // 빈 화면 표시
        }

  
        // 첫 번째 전화번호를 선택된 번호로 설정 (옵션)
        if (newPhoneNumbers.length > 0 && !selectedPhoneNumber) {
          setSelectedPhoneNumber(newPhoneNumbers[0]);
        }
      });
  
  
      // 서버로부터 전화 신호(ACK, BYE)를 받을 때 실행
      socket.on('sceq', async (data) => {
        const sceq = data.sceq;
        const phoneNumber = data.from;

        // 최신 phoneNumberList 상태를 가져옵니다.
        if (sceq === 'ACK') {
          setIsCallActive(true);  // 통화 활성화
          setSummary(null);       // 요약 초기화

          setPhoneNumberList2(prevList => {
            //console.log('[WebSocketComponent] socket connection ::: (현재 phoneNumberList : ', prevList, ')');
              
            //const isExistingNumber = prevList.includes(phoneNumber);
            //console.log('[WebSocketComponent] socket connection ::: (isExistingNumber : ', isExistingNumber, ')');
            const isExistingNumber = prevList.find(item => item.number === phoneNumber);
            //console.log('[WebSocketComponent] socket connection ::: (isExistingNumber : ', isExistingNumber, ')');
            
            
            // 신규번호 수신
            if (!isExistingNumber) {
              console.log('[WebSocketComponent] socket connection ::: 신규번호 수신 [', phoneNumber, ']');
              //const newList = [phoneNumber, ...prevList];
              const newList = [{ number: phoneNumber, clientName: null }, ...prevList];
              
              setSelectedPhoneNumber(phoneNumber);
              setReceivedData([]); // 빈 화면 표시
              setReceivedData_json([]); // 빈 화면 표시
              
              //console.log('[WebSocketComponent] socket connection ::: (업데이트된 phoneNumberList : ', newList, ')');

              return newList;
            } 
            // 기존번호 수신
            else {
              //const newList = [phoneNumber, ...prevList.filter(item => item.number !== phoneNumber)];
              const existingItem = prevList.find(item => item.number === phoneNumber);
              const updatedItem = { ...existingItem, clientName: existingItem.clientName }; // clientName 업데이트
              const newList = [updatedItem, ...prevList.filter(item => item.number !== phoneNumber)];
              
              setSelectedPhoneNumber(phoneNumber);
              
              // TODO phoneNumber 에 매칭되는 seqNos 가 넘어가야 한다.
              setConnData(prevConnData => {
                const matchingItem = prevConnData.find(item => item.caller === phoneNumber);
                const seqNos = matchingItem ? matchingItem.seq_nos : null;

                // 메시지 리스트 조회
                /*
                fetchMessageList(userNo, seqNos).catch(error => {
                  console.log('메시지 리스트 조회 에러:', error);
                  setReceivedData([]); // 에러 발생 시 빈 화면 표시
                }); 
                */

                // 메시지 리스트 조회 JSON
                /*
                fetchMessageList_json2(userNo, phoneNumber).catch(error => {
                  console.log('메시지 리스트 조회 JSON 에러:', error);
                  setReceivedData_json([]); // 에러 발생 시 빈 화면 표시
                });
                */

                fetchMessageListToJSON(userNo, phoneNumber).catch(error => {
                  console.log('메시지 리스트 조회 JSON 에러:', error);
                  setReceivedData_json([]); // 에러 발생 시 빈 화면 표시
                });
                
                return prevConnData;
              });

              console.log('업데이트된 phoneNumberList:', newList);
              return newList;
            }
          });
        }
        // 통화 종료
        else if (sceq === 'BYE') {
          // connData 0 Index 에 번호,seqNos 추가
          setConnData(prevList => {
            const updatedList = [...prevList];
            updatedList[0] = { ...updatedList[0], caller: phoneNumber, seq_nos: data.call_id };
            return updatedList;
          });

          // 통화 내역 저장
          setTranscription((prevList) => {
            //saveCallLogs(data.call_id, prevList);
            
            // 통화내역 요약 처리 필요
            try {
              const textBuffer = [];
              if (prevList === null) {
                setIsCallActive(false); // 통화 비활성화
                return;
              }
  
              prevList.map(item => {
                if (item.text) {
                  textBuffer.push(item.text);
                }
              });
  
              // textBuffer의 모든 문자열을 줄바꿈으로 구분하여 하나로 합침
              const summaryText = textBuffer.join('\n');
              
              saveCallLogs(userNo, data.call_id, data.from, prevList, summaryText);
  
              return null;
            } catch (error) {
              console.log('에러: ', error);
              setIsCallActive(false); // 통화 비활성화
            }
            
          });
    
          // transcription 초기화
          setTranscription(null);

          // TODO connData 새로고침
        }

        return;

        
        /**
         * TODO 
         * [ACK] 신호일 때
         *  1) 리스트에 있는 번호면 해당 번호가 리스트 1열에 올라가고 선택.
         *  2) 리스트에 없는 번호면 해당 번호 추가하고 선택.
         * [BYE] 신호일 때
         */
        
        // 통화 시작
        if (sceq === 'ACK') {
          console.log('ACK :::', data);

          const phoneNumber = data.from;
          const isExistingNumber = phoneNumberList.includes(phoneNumber);
          
          // 기존 번호 수신
          if (isExistingNumber) {
            console.log(`기존 번호 [${phoneNumber}]`);
            updatePhoneNumberList(phoneNumber);

            try {
              await fetchMessageList(phoneNumber);
            } catch (error) {
              console.log('메시지 리스트 조회 에러:', error)
              setReceivedData([]); // 에러 발생 시 빈 화면 표시
            }
          } 
          // 새로운 번호 수신
          else {
            console.log(`새로운 번호 [${phoneNumber}]`);

            setPhoneNumberList(prevList => [phoneNumber, ...prevList]);
            setSelectedPhoneNumber(phoneNumber);
            setReceivedData([]); // 빈 화면 표시 
          }
        } // 통화 시작 로직 끝


        // 통화 종료
        if (sceq === 'BYE') {
          console.log('BYE :::', data);
        } // 통화 종료 로직 끝
        
      }); 
  
  
      // 서버로부터 통화 데이터를 받을 때마다 실행
      socket.on('transcribe', (data) => {
        const status = data.result_code;
        
        // 무음 처리
        if ( status === 201 ) {
          return;
        }

        // 새로운 통화데이터를 transcription에 저장
        setTranscription(prevList => {
          // prevList가 null 또는 undefined인 경우를 대비해 빈 배열을 기본값으로 사용
          const currentList = prevList || [];
          return [...currentList, data];
        });

        setNewDataItem(prevItem => {
          //const updatedContent = [...prevItem.content, data];
          //return { ...prevItem, content: updatedContent };

          // prevItem.content가 배열인지 확인하고, 아니면 새 배열 생성
          const prevContent = Array.isArray(prevItem.content) ? prevItem.content : [];
          return { ...prevItem, content: [...prevContent, data] };
        });
        

        // 수신된 통화데이터를 상태에 추가
        setReceivedData_json(prevData => {
          //console.log('Previous receivedData_json:', prevData); // 디버깅을 위한 로그
          
          // prevData가 문자열인 경우 JSON 파싱 시도
          let parsedData;
          if (typeof prevData === 'string') {
            try {
              parsedData = JSON.parse(prevData);
            } catch (error) {
              console.error('JSON 파싱 오류:', error);
              parsedData = [];
            }
          } else if (Array.isArray(prevData)) {
            parsedData = prevData;
          } else {
            console.warn('receivedData_json의 형식이 예상과 다릅니다. 빈 배열로 초기화합니다.');
            parsedData = [];
          }

          // 현재 활성 세션 확인
          const currentSession = parsedData.length > 0 ? parsedData[parsedData.length - 1] : null;

          if (currentSession && currentSession.seqNo === newDataItem.seqNo) {
            // 현재 세션이 존재하고 seqNo가 일치하면 해당 세션에 새 데이터 추가
            const updatedData = [...parsedData];
            const lastIndex = updatedData.length - 1;

            updatedData[lastIndex] = {
              ...updatedData[lastIndex],
              content: [...(updatedData[lastIndex].content || []), data]
            };

            return JSON.stringify(updatedData);
          } else {
            // 새로운 세션 시작 또는 첫 데이터인 경우
            return JSON.stringify([
              ...parsedData,
              {
                seqNo: newDataItem.seqNo,
                recodingNo: newDataItem.recodingNo,
                content: [data]
              }
            ]);
          }
        });
        

        //updatePhoneNumberList(data.phone_number);
        
        // 데이터 수신 시, 자동으로 해당 전화번호 선택 (옵션)
        //setSelectedPhoneNumber(data.phone_number);
      });
  
  
      // 서버로부터 통화 기록 리스트 화면 출력
      socket.on('messageList', (list) => {
        //console.log('messageList 응답 :::',list);
        setReceivedData(list);
      });


      // 서버로부터 통화 기록 리스트 화면 출력 (JSON)
      //socket.on('messageList_json', (list) => {
      socket.on('messageList_json2', (list) => {
        //console.log('[WebSocketComponent] messageList_json :::', list);
        setReceivedData_json(list);
      });


      // 서버로부터 통화 요약 (Summary) 결과 받기
      socket.on('summary', (result) => {
        //console.log('[WebSocketComponent] ChatGPT 요약 결과 :::', result.summary);
        setSummary(result.summary);
        updateReceivedData(receivedData_json, result.summary);
        setIsCallActive(false); // 통화 비활성화
      });
    };


    initializeComponent();
    

    // 컴포넌트가 언마운트될 때 소켓 연결 해제
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);  // 빈 배열로 설정하여 컴포넌트가 마운트될 때만 실행


  // updatePhoneNumberList 함수 수정
  const updatePhoneNumberList = (phoneNumber, clientName=null) => {
    setPhoneNumberList2((prevList) => {
      //const newList = [phoneNumber, ...prevList.filter(phone => phone !== phoneNumber)];
      //return newList;

      const existingItem = prevList.find(item => item.number === phoneNumber);
      const newItem = existingItem || { number: phoneNumber, clientName };
      const newList = [newItem, ...prevList.filter(item => item.number !== phoneNumber)];
      console.log('Updated phoneNumberList2:', newList);
      return newList;
    });

    setSelectedPhoneNumber(phoneNumber);
  };

  // 번호 리스트 업데이트
  const updatePhoneNumberList2 = (phoneNumber) => {
    setPhoneNumberList((prevList) => {
      let newList = prevList.filter(phone => phone !== phoneNumber);
      newList.unshift(phoneNumber);
      console.log('newList', newList);
      return newList;
    });

    setSelectedPhoneNumber(phoneNumber);
  };


  // 번호에 매칭되는 통화 기록 조회
  const fetchMessageList = async (userNo, seqNos) => {
    const URL = 'https://popbio.ai/api/aicc/messageList'

    try{
      console.log('[WebSocketComponent] fetchMessageList() :::', userNo, seqNos);

      const jsonData = {
        "userNo" : userNo,
        "seqNos" : Array.isArray(seqNos) ? seqNos : [seqNos]
      };

      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        throw new Error('[WebSocketComponent] fetchMessageList() 에러 ::: 네트워크 응답이 올바르지 않습니다.');
      }
      
    } catch (error) {
      console.error('[WebSocketComponent] fetchMessageList() 에러 :::', error);
    }
  };


  // 전화번호로 매칭되는 통화 기록 조회 (JSON)
  const fetchMessageList_json = async (userNo, seqNos) => {
    const URL = 'https://popbio.ai/api/aicc/messageList_json'
    try{
      console.log('[WebSocketComponent] fetchMessageList_json() :::', userNo, seqNos);
      
      const jsonData = {
        "userNo" : userNo,
        "seqNos" : Array.isArray(seqNos) ? seqNos : [seqNos]
      };

      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        throw new Error('[WebSocketComponent] fetchMessageList_json() 에러 ::: 네트워크 응답이 올바르지 않습니다.');
      }

    } catch (error) {
      console.error('[WebSocketComponent] fetchMessageList_json() 에러 :::', error);
    }
  };


  // 전화번호로 매칭되는 통화 기록 조회2 (JSON)
  /*
  const fetchMessageList_json2 = async (userNo, phoneNumber) => {
    const URL = 'https://popbio.ai/api/aicc/messageList_json2'
    try{
      const jsonData = {
        "userNo"      : userNo,
        "phoneNumber" : phoneNumber
      };

      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        throw new Error('[WebSocketComponent] fetchMessageList_json2() 에러 ::: 네트워크 응답이 올바르지 않습니다.');
      }

    } catch (error) {
      console.error('[WebSocketComponent] fetchMessageList_json2() 에러 :::', error);
    }
  };
  */


  // 통화 기록 저장
  const saveCallLogs = async (userNo, callId, phoneNumber, transcriptions, summaryText) => {
  
    const URL = 'https://popbio.ai/api/aicc/saveCallLogs'
    try {
      console.log('[WebSocketComponent] saveCallLogs() <callId ::: ', callId, '>');

      const jsonData = {
        "userNo"          : userNo,
        "callId"          : callId,
        "phoneNumber"     : phoneNumber,
        "transcriptions"  : transcriptions,
        "summaryText"     : summaryText
      };

      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        throw new Error('[WebSocketComponent] saveCallLogs() 에러 ::: 네트워크 응답이 올바르지 않습니다.');
      }
      
      console.log('[WebSocketComponent] saveCallLogs() 결과 ::: ', response.data);
    } catch (error) {
      console.error('[WebSocketComponent] saveCallLogs() 에러 :::', error);
    }
  };


  // receivedData 업데이트
  const updateReceivedData = (prevData, data) => {
    console.log('type of prevData:', typeof prevData);
          
    // prevData가 문자열인 경우 JSON 파싱 시도
    let parsedData;
    if (typeof prevData === 'string') {
      try {
        parsedData = JSON.parse(prevData);
      } catch (error) {
        console.error('JSON 파싱 오류:', error);
        parsedData = [];
      }
    } else if (Array.isArray(prevData)) {
      parsedData = prevData;
    } else {
      console.warn('receivedData_json의 형식이 예상과 다릅니다. 빈 배열로 초기화합니다.');
      parsedData = [];
    }

    // 현재 활성 세션 확인
    const currentSession = parsedData.length > 0 ? parsedData[parsedData.length - 1] : null;

    if (currentSession && currentSession.seqNo === newDataItem.seqNo) {
      // 현재 세션이 존재하고 seqNo가 일치하면 해당 세션에 새 데이터 추가
      const updatedData = [...parsedData];
      const lastIndex = updatedData.length - 1;

      updatedData[lastIndex] = {
        ...updatedData[lastIndex],
        content: [...(updatedData[lastIndex].content || []), data]
      };

      return JSON.stringify(updatedData);
    } else {
      // 새로운 세션 시작 또는 첫 데이터인 경우
      return JSON.stringify([
        ...parsedData,
        {
          seqNo: newDataItem.seqNo,
          recodingNo: newDataItem.recodingNo,
          content: [data]
        }
      ]);
    }
  };


  // 초기 전화번호 목록 설정
  /*
  useEffect(() => {
    const uniquePhoneNumbers = Array.from(new Set(receivedData.map(data => data.phone_number)));
    
    // 중복 제거 및 병합
    setPhoneNumberList((prevList) => [...new Set([...prevList, ...uniquePhoneNumbers])]); 
  }, [receivedData]);
  */

  
  // 선택된 전화번호에 해당하는 채팅 메시지 필터링
  //const filteredMessages = receivedData.filter(data => data.phone_number === selectedPhoneNumber);
  const filteredMessages = receivedData;
  const filteredMessages_json = receivedData_json;
  const summaryText = summary;
  /*
  const filteredMessages = receivedData.map(data => ({
    seqNo: data.seqNo,
    recodingNo: data.recodingNo,
    type: data.type,
    text: data.text
  }));
  */

  

  return (
    <div>
      
      {/* TitleBar 컴포넌트를 사용하여 제목 표시 */}
      <TitleBar title={title} />

      <div className="chat-container">
        <UserList
          phoneNumberList={phoneNumberList}
          phoneNumberList2={phoneNumberList2}
          selectedPhoneNumber={selectedPhoneNumber}
          setSelectedPhoneNumber={setSelectedPhoneNumber}
          selectedMessageList={fetchMessageList}
          selectedMessageList_json={fetchMessageList_json}
          selectedMessageList_json2={fetchMessageListToJSON}
          connData={connData}
          userNo={userNo}
          isCallActive={isCallActive}
        />

        {/*<ChatBox filteredMessages={filteredMessages} />*/}
        <ChatBoxJSON 
          filteredMessages_json={filteredMessages_json} 
          summary={summaryText}
          />
          {/*callHistory={callHistory[selectedPhoneNumber] || []}*/}
      </div>

    </div>

    
  );
};

export default WebSocketComponent;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// 번호에 매칭되는 통화 기록 조회
async function fetchMessageList(userNo, seqNos) {
  const URL = 'https://popbio.ai/api/aicc/messageList'
  try{
    console.log(`[WebSocketComponent] fetchMessageList() ::: userNo [${userNo}], seqNos[${seqNos}]`);

    const jsonData = {
      "userNo" : userNo,
      "seqNos" : Array.isArray(seqNos) ? seqNos : [seqNos]
    };

    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonData),
    });

    if (!response.ok) {
      throw new Error('[WebSocketComponent] fetchMessageList() 에러 ::: 네트워크 응답이 올바르지 않습니다.');
    }
    
  } catch (error) {
    console.error('[WebSocketComponent] fetchMessageList() 에러 :::', error);
  }
}


// 전화번호로 매칭되는 통화 기록 조회2 (JSON)
async function fetchMessageListToJSON(userNo, phoneNumber) {
  const URL = 'https://popbio.ai/api/aicc/messageList_json2'
  try{
    const jsonData = {
      "userNo"      : userNo,
      "phoneNumber" : phoneNumber
    };

    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonData),
    });

    if (!response.ok) {
      throw new Error('[WebSocketComponent] fetchMessageList_json2() 에러 ::: 네트워크 응답이 올바르지 않습니다.');
    }

  } catch (error) {
    console.error('[WebSocketComponent] fetchMessageList_json2() 에러 :::', error);
  }
}
